<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          {{ $t("FOOD_TESTS") }}
        </div>

        <div class="card-toolbar">
          <v-dialog
            :retain-focus="false"
            v-model="dialogAdd"
            max-width="1200px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon2-plus"></i>
                {{ $t("ADD_NEW") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ $t("FOOD_TEST_CREATE") }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="5">
                      <v-text-field
                        v-model="form.name"
                        :label="$t('NAME')"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="5">
                      <v-text-field
                        v-model="form.method"
                        :label="$t('METHOD')"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="5">
                      <v-text-field
                        v-model="form.device"
                        :label="$t('DEVICE')"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="5">
                      <v-text-field
                        v-model="form.cookingTime"
                        :label="$t('COOKING_TIME')"
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="5">
                      <v-text-field
                        v-model="form.tempMin"
                        :label="$t('TEMPERATURE_MIN')"
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="5">
                      <v-text-field
                        v-model="form.tempMax"
                        :label="$t('TEMPERATURE_MAX')"
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save()"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :sort-desc="false"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingDailyTasks"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="kitchen-equipments-table"
        >
          <v-divider inset></v-divider>
          <template v-slot:top>
            <SearchTools
              :searchQuery.sync="searchQuery"
              @do-search="doSearch"
              @do-clear="doClear"
            >
            </SearchTools>
          </template>

          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:item.method="{ item }">
            {{ item.method }}
          </template>

          <template v-slot:item.device="{ item }">
            {{ item.device }}
          </template>

          <template v-slot:item.cookingTime="{ item }">
            {{ item.cookingTime }}
          </template>

          <template v-slot:item.tempMin="{ item }">
            {{ item.tempMin }}
          </template>
          <template v-slot:item.tempMax="{ item }">
            {{ item.tempMax }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip color="red" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="red"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("DELETE") }}</span>
            </v-tooltip>
            <v-dialog
              v-model="dialogEdit"
              max-width="1200px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="black"
                      @click="edit(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("EDIT") }}</span>
                </v-tooltip>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ $t("FOOD_TEST_EDIT") }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="4" md="5">
                        <v-text-field
                          v-model="editForm.name"
                          :label="$t('NAME')"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="5">
                        <v-text-field
                          v-model="editForm.method"
                          :label="$t('METHOD')"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="5">
                        <v-text-field
                          v-model="editForm.device"
                          :label="$t('DEVICE')"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="5">
                        <v-text-field
                          v-model="editForm.cookingTime"
                          :label="$t('COOKING_TIME')"
                          hide-details
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="5">
                        <v-text-field
                          v-model="editForm.tempMin"
                          :label="$t('TEMPERATURE_MIN')"
                          hide-details
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="5">
                        <v-text-field
                          v-model="editForm.tempMax"
                          :label="$t('TEMPERATURE_MAX')"
                          hide-details
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="update()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              :retain-focus="false"
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this Fridge?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<script>
import {
  FETCH_FOOD_TESTS,
  SAVE_FOOD_TEST,
  UPDATE_FOOD_TEST,
  DELETE_FOOD_TEST
} from "@/modules/food-tests/store/food-tests.module";
//Fetch Fridges
import { mapGetters } from "vuex";
import SearchTools from "@/core/components/table/SearchTools.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin";

export default {
  name: "FoodTesting",
  mixins: [permissionMixin, formBuilderMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      firstLoader: true,
      search: "",
      dialogAdd: false,
      dialogEdit: false,
      dialogDelete: false,
      items: [],
      stores: [],
      totalItems: 100,
      searchQuery: "",
      form: {
        name: "",
        method: "",
        device: "",
        cookingTime: "",
        tempMin: "",
        tempMax: ""
      },
      clearFormEntry: {
        name: "",
        method: "",
        device: "",
        cookingTime: "",
        tempMin: "",
        tempMax: ""
      },
      editForm: {
        name: "",
        method: "",
        device: "",
        cookingTime: "",
        tempMin: "",
        tempMax: ""
      },
      deleteRecord: null,
      filters: {
        name: ""
      },
      options: {
        descending: false,
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      headers: [
        { text: "Име", value: "name", sortable: false },
        { text: "Метод", value: "method", sortable: false },
        { text: "Уред", value: "device", sortable: false },
        {
          text: "Готвене (минути)",
          value: "cookingTime",
          sortable: false
        },
        {
          text: "Минимална Темп.",
          value: "tempMin",
          sortable: false
        },
        {
          text: "Максимална Темп.",
          value: "tempMax",
          sortable: false
        },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  components: {
    SearchTools
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Food Testing", route: { name: "list-food-testing" } },
      { title: "Food Testing List" }
    ]);
  },
  computed: {
    ...mapGetters(["getFoodTests", "isLoadingFoodTests"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  watch: {
    dialogAdd(val) {
      val || this.close();
    },
    dialogEdit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    close() {
      let vm = this;
      vm.dialogAdd = false;
      vm.dialogEdit = false;
    },
    closeDelete() {
      let vm = this;
      vm.dialogDelete = false;
    },
    deleteItem(item) {
      this.deleteRecord = Object.assign({}, item);
      this.dialogDelete = true;
    },
    edit(item) {
      this.editForm = Object.assign({}, item);
      this.dialogEdit = true;
    },
    save() {
      let vm = this;
      let payload = {
        name: vm.form.name,
        method: vm.form.method,
        device: vm.form.device,
        cooking_time: vm.form.cookingTime,
        temp_min: vm.form.tempMin,
        temp_max: vm.form.tempMax
      };
      this.$store
        .dispatch(SAVE_FOOD_TEST, payload)
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function () {
            vm.form = vm.clearFormEntry;
            vm.close();
            vm.doSearch();
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    update() {
      let vm = this;
      let id = vm.editForm.id;

      let payload = {
        name: vm.editForm.name,
        method: vm.editForm.method,
        device: vm.editForm.device,
        cooking_time: vm.editForm.cookingTime,
        temp_min: vm.editForm.tempMin,
        temp_max: vm.editForm.tempMax
      };

      this.$store
        .dispatch(UPDATE_FOOD_TEST, { id: id, payload: payload })
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function () {
            vm.close();
            vm.doSearch();
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    deleteItemConfirm() {
      let vm = this;
      this.$store
        .dispatch(DELETE_FOOD_TEST, vm.deleteRecord.id)
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function () {
            this.closeDelete();
            this.doSearch();
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.$router.push({ name: "list-stores" });
          }
        });
      this.doSearch();
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;

      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_FOOD_TESTS, apiParams)
        .then((data) => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function () {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "primary";
      else return "";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Ресторанти";
      else return "Складове";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-silverware-variant";
      else return "mdi-package-down";
    }
  }
};
</script>
